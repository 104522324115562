import { Country, DayOfWeek, StoreOpeningTime } from '../../generated/graphql'

export enum FacilityIcon {
  YES = 'YES',
  NO = 'NO',
}

export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
  TEMPORARILY_CLOSED = 'TEMPORARILY_CLOSED',
}

export interface StoreFacility {
  icon: FacilityIcon
  title: string
}

export interface StoreDetailsData {
  store: {
    id: string
    displayName: string
    urlTag: string
    phoneNumber: string
    longitude: number
    latitude: number
    relativeDistance: number
    status: Status
    storeFacility: StoreFacility[]
    address: {
      country: Country
      addresseeName: string
      addressLine1: string
      addressLine2: string
      addressLine3?: string
      addressLine4: string
      addressLine5: string
      companyName: string
      state?: string
      phoneNumber: string
      postalCode: string
      clickAndCollect?: boolean
    }
    openingTimes: StoreOpeningTime[]
    nearbyStores?: StoreDetailsData['store'][]
  }
}

const FACILITIES_MOCK: StoreFacility[] = [
  {
    title: 'Facility name',
    icon: FacilityIcon.YES,
  },
  {
    title: 'Facility name',
    icon: FacilityIcon.YES,
  },
  {
    title: 'Facility name',
    icon: FacilityIcon.YES,
  },
  {
    title: 'Facility name',
    icon: FacilityIcon.NO,
  },
]

export const STORE_MOCK: StoreDetailsData = {
  store: {
    id: '101150',
    displayName: 'Homebase Leighton Buzzard',
    urlTag: 'South-East-England-Homebase-Leighton-Buzzard',
    phoneNumber: '03456 407649',
    longitude: -0.66846,
    latitude: 51.918694,
    relativeDistance: 0.7,
    status: Status.OPEN,
    storeFacility: FACILITIES_MOCK,
    address: {
      country: Country.Gb,
      addresseeName: 'Homebase Leighton Buzzard',
      addressLine1: 'Vimy Road',
      addressLine2: 'Linslade',
      addressLine3: '',
      addressLine4: 'Leighton Buzzard',
      addressLine5: 'South East England',
      companyName: 'Homebase',
      phoneNumber: '03456 407649',
      postalCode: 'LU7 1ER',
      clickAndCollect: true,
    },
    openingTimes: [
      {
        day: DayOfWeek.Monday,
        openingTime: '09:00:00',
        closingTime: '18:00:00',
      },
      {
        day: DayOfWeek.Tuesday,
        openingTime: '09:00:00',
        closingTime: '18:00:00',
      },
      {
        day: DayOfWeek.Wednesday,
        openingTime: '09:00:00',
        closingTime: '18:00:00',
      },
      {
        day: DayOfWeek.Thursday,
        openingTime: '09:00:00',
        closingTime: '18:00:00',
      },
      {
        day: DayOfWeek.Friday,
        openingTime: '09:00:00',
        closingTime: '18:00:00',
      },
      {
        day: DayOfWeek.Saturday,
        openingTime: '09:00:00',
        closingTime: '18:00:00',
      },
      {
        day: DayOfWeek.Sunday,
        openingTime: '09:00:00',
        closingTime: '18:00:00',
      },
    ],
    nearbyStores: [
      {
        id: '101151',
        displayName: 'Homebase Felixstowe',
        urlTag: 'South-East-England-Homebase-Felixstowe',
        phoneNumber: '03456 407650',
        longitude: 1.349824,
        latitude: 51.966744,
        relativeDistance: 1.6,
        status: Status.OPEN,
        storeFacility: FACILITIES_MOCK,
        address: {
          country: Country.Gb,
          addresseeName: 'Homebase Felixstowe',
          addressLine1: 'Great Eastern Square',
          addressLine2: 'Station Approach',
          addressLine3: '',
          addressLine4: 'Felixstowe',
          addressLine5: 'South East England',
          companyName: 'Homebase',
          phoneNumber: '03456 407650',
          postalCode: 'IP11 7DY',
          clickAndCollect: true,
        },
        openingTimes: [
          {
            day: DayOfWeek.Monday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Tuesday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Wednesday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Thursday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Friday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Saturday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Sunday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
        ],
      },
      {
        id: '101151',
        displayName: 'Homebase Felixstowe',
        urlTag: 'South-East-England-Homebase-Felixstowe',
        phoneNumber: '03456 407650',
        longitude: 1.349824,
        latitude: 51.966744,
        relativeDistance: 2.2,
        status: Status.OPEN,
        address: {
          country: Country.Gb,
          addresseeName: 'Homebase Felixstowe',
          addressLine1: 'Great Eastern Square',
          addressLine2: 'Station Approach',
          addressLine3: '',
          addressLine4: 'Felixstowe',
          addressLine5: 'South East England',
          companyName: 'Homebase',
          phoneNumber: '03456 407650',
          postalCode: 'IP11 7DY',
          clickAndCollect: true,
        },
        storeFacility: FACILITIES_MOCK,
        openingTimes: [
          {
            day: DayOfWeek.Monday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Tuesday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Wednesday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Thursday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Friday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Saturday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Sunday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
        ],
      },
      {
        id: '101151',
        displayName: 'Homebase Felixstowe',
        urlTag: 'South-East-England-Homebase-Felixstowe',
        phoneNumber: '03456 407650',
        longitude: 1.349824,
        latitude: 51.966744,
        relativeDistance: 1.0,
        status: Status.OPEN,
        address: {
          country: Country.Gb,
          addresseeName: 'Homebase Felixstowe',
          addressLine1: 'Great Eastern Square',
          addressLine2: 'Station Approach',
          addressLine3: '',
          addressLine4: 'Felixstowe',
          addressLine5: 'South East England',
          companyName: 'Homebase',
          phoneNumber: '03456 407650',
          postalCode: 'IP11 7DY',
          clickAndCollect: true,
        },
        storeFacility: FACILITIES_MOCK,
        openingTimes: [
          {
            day: DayOfWeek.Monday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Tuesday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Wednesday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Thursday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Friday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Saturday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
          {
            day: DayOfWeek.Sunday,
            openingTime: '09:00:00',
            closingTime: '18:00:00',
          },
        ],
      },
    ],
  },
}
